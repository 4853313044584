import "../styles/canvas-container.scss";
import { useSelector, useDispatch } from "react-redux";
import { State } from "../reducers";
import { useRef, useEffect, useState } from "react";
import {
  PageMode,
  VEHICLE,
  VEHICLE_WIDTH_IN_FT,
  STAGE_BORDER_WIDTH_IN_PIXEL,
  MAIN_ACTIONS,
  CAMERA_LENS,
  CAMERA_LENS_LENGTH_RANGE,
} from "../constants";
import { fabric } from "fabric";
import compact from "../assets/compact.png";
import fullsize from "../assets/fullsize.png";
import trucks from "../assets/trucks.png";
import cameraImage from "../assets/camera.png";

const INIT_STAGE_INFO = {
  stageLengthInFt: 0,
  stageWidthInFt: 0,
  pixelsPerFt: 0,
  stageWidthInPixel: 0,
  stageHeightInPixel: 0,
};

const INIT_VEHICLE_INFO = {
  vehicleLengthInFt: 0,
  vehicleWidthInFt: 0,
  vehicleWidthInPixel: 0,
  vehicleHeightInPixel: 0,
};

function CanvasContainer() {
  const dispatch = useDispatch();
  const canvasContainer = useRef(null);
  const canvasMain = useRef(null);
  const [stageInfo, setStageInfo] = useState(INIT_STAGE_INFO);
  const [vehicleInfo, setVehicleInfo] = useState(INIT_VEHICLE_INFO);
  const [canvas, setCanvas] = useState(null);
  const pageMode = useSelector((state: State) => state.main.pageMode);
  const vehicle = useSelector((state: State) => state.main.vehicle);
  const camera = useSelector((state: State) => state.main.camera);

  const stageDimensions = useSelector(
    (state: State) => state.main.stageDimensions
  );

  useEffect(() => {
    switch (pageMode) {
      case PageMode.VEHICLE_SIZE:
        initCanvas();
        break;
      case PageMode.CAMERA_SIZE:
        setVehicle();
        showCamera();
        break;
      case PageMode.LENS_LENGTH:
        showResult();
        break;
    }
  }, [pageMode]);

  function initCanvas() {
    const canvasContainerEl: HTMLDivElement = canvasContainer.current;
    const canvasMainEl: HTMLCanvasElement = canvasMain.current;
    const canvasContainerWidth = canvasContainerEl.clientWidth;
    const canvasContainerHeight = canvasContainerEl.clientHeight;

    canvasMainEl.width = canvasContainerWidth;
    canvasMainEl.height = canvasContainerHeight;

    const canvas = new fabric.Canvas(canvasMainEl, {
      selection: false,
    });

    fabric.filterBackend = fabric.initFilterBackend();
    try {
      let webglBackend = new fabric.WebglFilterBackend();
      fabric.filterBackend = webglBackend;
    } catch (e) {
      let canvas2dBackend = new fabric.Canvas2dFilterBackend();
      fabric.filterBackend = canvas2dBackend;
    }

    // per pixel calculation
    let stageLengthInFt = 0;
    let stageWidthInFt = 0;

    if (stageDimensions.isDistance) {
      stageWidthInFt = stageDimensions.distance * 2 + VEHICLE_WIDTH_IN_FT;
    } else {
      stageLengthInFt =
        stageDimensions.length > stageDimensions.width
          ? stageDimensions.length
          : stageDimensions.width;
      stageWidthInFt =
        stageDimensions.length > stageDimensions.width
          ? stageDimensions.width
          : stageDimensions.length;
    }

    const maxStageWidth = Math.floor(canvasContainerWidth * 0.8);
    const maxStageHeight = Math.floor(canvasContainerHeight * 0.75);

    let pixelsPerFt = 0;

    if (stageDimensions.isDistance) {
      pixelsPerFt = maxStageHeight / stageWidthInFt;
      stageLengthInFt = Math.floor(maxStageWidth / pixelsPerFt);
    } else {
      pixelsPerFt = Math.min(
        maxStageWidth / stageLengthInFt,
        maxStageHeight / stageWidthInFt
      );
    }

    // draw stage length/width and boundaries
    const stageWidthInPixel = stageLengthInFt * pixelsPerFt;
    const stageHeightInPixel = stageWidthInFt * pixelsPerFt;
    const stageRectLeft = canvasContainerWidth / 2 - stageWidthInPixel / 2;
    const stageRectTop = canvasContainerHeight / 2 - stageHeightInPixel / 2;

    setStageInfo({
      stageLengthInFt,
      stageWidthInFt,
      pixelsPerFt,
      stageWidthInPixel,
      stageHeightInPixel,
    });

    const stageRect = new fabric.Rect({
      left: canvasContainerWidth / 2 - stageWidthInPixel / 2,
      top: canvasContainerHeight / 2 - stageHeightInPixel / 2,
      fill: "lightgray",
      width: stageWidthInPixel,
      height: stageHeightInPixel,
      strokeWidth: STAGE_BORDER_WIDTH_IN_PIXEL,
      stroke: "darkgray",
      selectable: false,
      hoverCursor: "default",
    });

    canvas.add(stageRect);

    var stageLengthLabel = new fabric.Text(`Length (ft): ${stageLengthInFt}`, {
      left: canvasContainerWidth / 2 - 50,
      top: stageRectTop - 50,
      width: 100,
      fontSize: 20,
      fontWeight: "bold",
      textAlight: "center",
      selectable: false,
      hoverCursor: "default",
    });

    canvas.add(stageLengthLabel);

    var stageWidthLabel = new fabric.Text(`Width (ft): ${stageWidthInFt}`, {
      left: stageRectLeft - 100,
      top: canvasContainerHeight / 2 - 10,
      width: 100,
      fontSize: 20,
      fontWeight: "bold",
      textAlight: "center",
      centeredRotation: true,
      selectable: false,
      hoverCursor: "default",
    });

    stageWidthLabel.rotate(90);

    canvas.add(stageWidthLabel).renderAll();

    setCanvas(canvas);
  }

  async function setVehicle() {
    let vehicleImage = null;
    let vehicleLengthInFt = 0;
    let vehicleWidthInFt = VEHICLE_WIDTH_IN_FT;

    switch (vehicle) {
      case VEHICLE.COMPACT:
        vehicleImage = compact;
        vehicleLengthInFt = 15;
        break;
      case VEHICLE.FULLSIZE:
        vehicleImage = fullsize;
        vehicleLengthInFt = 18;
        break;
      case VEHICLE.TRUCKS:
        vehicleImage = trucks;
        vehicleLengthInFt = 20;
        break;
    }

    const pixelsPerFt = stageInfo.pixelsPerFt;
    const vehicleWidthInPixel = vehicleLengthInFt * pixelsPerFt;
    const vehicleHeightInPixel = vehicleWidthInFt * pixelsPerFt;

    setVehicleInfo({
      vehicleLengthInFt,
      vehicleWidthInFt,
      vehicleWidthInPixel,
      vehicleHeightInPixel,
    });

    fabric.Image.fromURL(
      vehicleImage,
      function (img) {
        img.set({
          scaleX: vehicleWidthInPixel / img.width,
          scaleY: vehicleWidthInPixel / img.width,
        });
        canvas.add(img).renderAll();
        img.center();
      },
      {
        selectable: false,
        hoverCursor: "default",
      }
    );
  }

  function showCamera() {
    let cameraHeightInPixel =
      (canvas.height - stageInfo.stageHeightInPixel) / 2 -
      STAGE_BORDER_WIDTH_IN_PIXEL; // 5: STAGE_BORDER_WIDTH

    if (cameraHeightInPixel > 70) {
      cameraHeightInPixel = 70;
    }

    fabric.Image.fromURL(
      cameraImage,
      function (img) {
        img.set({
          scaleX: cameraHeightInPixel / img.height,
          scaleY: cameraHeightInPixel / img.height,
        });
        canvas.add(img).renderAll();
        img.centerH();
      },
      {
        top:
          canvas.height / 2 +
          stageInfo.stageHeightInPixel / 2 +
          STAGE_BORDER_WIDTH_IN_PIXEL,
        selectable: false,
        hoverCursor: "default",
      }
    );
  }

  function showResult() {
    const cameraDistanceInFt =
      (stageInfo.stageWidthInFt - vehicleInfo.vehicleWidthInFt) / 2;

    const cameraDistanceInPixel = cameraDistanceInFt * stageInfo.pixelsPerFt;

    var VehicleLengthLabel = new fabric.Text(
      `Vehicle Length (ft): ${vehicleInfo.vehicleLengthInFt}`,
      {
        left: 
          canvas.width / 2 - 50,
        top:
          canvas.height / 2 -
          vehicleInfo.vehicleHeightInPixel / 2 -
          50,
        // 50,
        width: 100,
        fontSize: 15,
        fontWeight: "bold",
        textAlight: "center",
        centeredRotation: true,
        selectable: false,
        hoverCursor: "default",
      }
    );

    var cameraDistanceLabel = new fabric.Text(
      `Distance (ft): ${cameraDistanceInFt}`,
      {
        left: (canvas.width - vehicleInfo.vehicleWidthInPixel) / 2 - 50,
        top:
          canvas.height / 2 +
          vehicleInfo.vehicleHeightInPixel / 2 +
          cameraDistanceInPixel / 2,
        // 50,
        width: 100,
        fontSize: 15,
        fontWeight: "bold",
        textAlight: "center",
        centeredRotation: true,
        selectable: false,
        hoverCursor: "default",
      }
    );

    cameraDistanceLabel.rotate(90);

    canvas.add(VehicleLengthLabel);
    canvas.add(cameraDistanceLabel);

    const vehicleLengthInFt = vehicleInfo.vehicleLengthInFt;

    const radianAngle = Math.atan(vehicleLengthInFt / 2 / cameraDistanceInFt);

    const angleInDegree = Math.floor(radiansToDegrees(radianAngle)) * 2;

    const angleLabel = new fabric.Text(`${angleInDegree}°`, {
      top:
        canvas.height / 2 +
        vehicleInfo.vehicleHeightInPixel / 2 +
        cameraDistanceInPixel / 2,
      width: 100,
      fontSize: 20,
      fontWeight: "bold",
      textAlight: "center",
      selectable: false,
      hoverCursor: "default",
    });

    canvas.add(angleLabel).renderAll();

    angleLabel.centerH();

    calculateLenslength(angleInDegree, cameraDistanceInPixel);
  }

  function calculateLenslength(angleInDegree, cameraDistanceInPixel) {
    const lensList = CAMERA_LENS[camera];
    let lensLength = -1;

    for (const degree in lensList) {
      if (parseFloat(degree) > angleInDegree) {
        lensLength = lensList[degree];
      }
    }
    showTriangle(lensLength, cameraDistanceInPixel)
    showAngle(angleInDegree);
    showLensLenthRange(lensLength, angleInDegree);

    dispatch({
      type: MAIN_ACTIONS.UPDATE_CAMERA_ANGLE,
      payload: angleInDegree,
    });

    dispatch({
      type: MAIN_ACTIONS.UPDATE_CAMERA_LENS_LENGTH,
      payload: lensLength,
    });
  }

  function showAngle(angleInDegree: number) {
    var angle = new fabric.Circle({
      radius: 50,
      left: canvas.width / 2 - 50,
      top: canvas.height / 2 + stageInfo.stageHeightInPixel / 2 - 50,
      startAngle: Math.PI + (Math.PI - degreesToRadians(angleInDegree)) / 2,
      endAngle:
        Math.PI +
        (Math.PI - degreesToRadians(angleInDegree)) / 2 +
        degreesToRadians(angleInDegree),
      stroke: "#000",
      strokeWidth: 2,
      fill: "",
      selectable: false,
    });

    canvas.add(angle);
  }

  function showLensLenthRange(lensLength: number, angleInDegree: number) {
    if (lensLength > 0) {
      const cameraRangelist = CAMERA_LENS_LENGTH_RANGE[camera];
      let cameraRange = null;

      for (const range in cameraRangelist) {
        if (
          cameraRangelist[range].start <= lensLength &&
          cameraRangelist[range].end >= lensLength
        ) {
          cameraRange = range;
          break;
        }
      }

      if (cameraRange) {
        const angleLabel = new fabric.Text(`Lens Type: ${cameraRange}`, {
          left: canvas.width / 2 + 100,
          top: canvas.height / 2 + stageInfo.stageHeightInPixel / 2 + 25,
          width: 100,
          fontSize: 20,
          fontWeight: "bold",
          textAlight: "center",
          selectable: false,
          hoverCursor: "default",
        });

        canvas.add(angleLabel);
      }
    }
  }

  function showTriangle(lensLength: number, cameraDistanceInPixel: number) {
    const cameraTriangle = new fabric.Triangle({
      width: vehicleInfo.vehicleWidthInPixel,
      height: cameraDistanceInPixel,
      fill: `${(lensLength) == -1 ? "#ff9090":"#ffe6cc"}`,
      strokeWidth: STAGE_BORDER_WIDTH_IN_PIXEL / 2,
      stroke: `${(lensLength) == -1 ? "#df4c4c":"#e4bc5b"}`,
      flipY: true,
      left: (canvas.width - vehicleInfo.vehicleWidthInPixel) / 2,
      top: canvas.height / 2 + vehicleInfo.vehicleHeightInPixel / 2,
      selectable: false,
      hoverCursor: "default",
    });

    canvas.add(cameraTriangle);
  }

  function radiansToDegrees(radians: number) {
    var pi = Math.PI;
    return radians * (180 / pi);
  }

  function degreesToRadians(degrees: number) {
    var pi = Math.PI;
    return degrees * (pi / 180);
  }

  return (
    <div className="canvas-wrapper" ref={canvasContainer}>
      <canvas className="canvas-main" ref={canvasMain}></canvas>
    </div>
  );
}

export { CanvasContainer };
