import { PageMode, MAIN_ACTIONS, VEHICLE, CAMERA } from "../constants";

export interface MainState {
  pageMode: PageMode;
  stageDimensions: {
    width?: number;
    length?: number;
    isDistance?: boolean;
    distance?: number;
  };
  vehicle: VEHICLE;
  camera: CAMERA;
  cameraAngle: number;
  cameraLensLength: number;
}

export const INIT_MAIN = {
  pageMode: PageMode.STAGE_DIMENSIONS,
  stageDimensions: {
    width: 24,
    length: 24,
    isDistance: false,
    distance: 12,
  },
  vehicle: null,
  camera: null,
  cameraAngle: null,
  cameraLensLength: null,
};

export function main(state = INIT_MAIN, action) {
  switch (action.type) {
    case MAIN_ACTIONS.UPDATE_STAGE_DIMENSION:
      return {
        ...state,
        stageDimensions: { ...action.payload },
      };
    case MAIN_ACTIONS.UPDATE_PAGE_MODE:
      return {
        ...state,
        pageMode: action.payload,
      };
    case MAIN_ACTIONS.UPDATE_VEHICLE:
      return {
        ...state,
        vehicle: action.payload,
      };
    case MAIN_ACTIONS.UPDATE_CAMERA:
      return {
        ...state,
        camera: action.payload,
      };
    case MAIN_ACTIONS.UPDATE_CAMERA_ANGLE:
      return {
        ...state,
        cameraAngle: action.payload,
      };
    case MAIN_ACTIONS.UPDATE_CAMERA_LENS_LENGTH:
      return {
        ...state,
        cameraLensLength: action.payload,
      };
    default:
      return state;
  }
}
