import { Breadcrumb } from "antd";
import "../styles/main-container.scss";
import { useDispatch, useSelector } from "react-redux";
import cx from "classnames";
import { State } from "../reducers";
import { MAIN_ACTIONS, PageMode } from "../constants";
import { CanvasContainer } from "./CanvasContainer";
import arrow from "../assets/arrow.png";

function MainContainer() {
  const pageMode = useSelector((state: State) => state.main.pageMode);
  const vehicleValue = useSelector((state: State) => state.main.vehicle);
  const dispatch = useDispatch();

  function handleStageNav() {
    dispatch({
      type: MAIN_ACTIONS.UPDATE_PAGE_MODE,
      payload: PageMode.STAGE_DIMENSIONS,
    });
  }
  
  function handleVehicleNav() {
    dispatch({
      type: MAIN_ACTIONS.UPDATE_PAGE_MODE,
      payload: PageMode.VEHICLE_SIZE,
    });
  }
  
  function handleCameraNav() {
    dispatch({
      type: MAIN_ACTIONS.UPDATE_PAGE_MODE,
      payload: PageMode.CAMERA_SIZE,
    });
  }
  

  return (
    <>
      <div className="main-header">
        <h1 className="main-title">Camera Lens Length Finder</h1>
        <Breadcrumb className="main-menu">
          <Breadcrumb.Item>
            <span
              className={`main-menu-item-nav ${cx("main-menu-item", {
                active: pageMode === PageMode.STAGE_DIMENSIONS,
              })}`}
              onClick={handleStageNav}
              
            >
              STAGE DIMENSIONS
            </span>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <span
              className={`main-menu-item-nav ${cx("main-menu-item", {
                active: pageMode === PageMode.VEHICLE_SIZE,
              })}`}
              onClick={handleVehicleNav}
            >
              VEHICLE SIZE
            </span>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <span
              className={vehicleValue? 
                `main-menu-item-nav ${cx("main-menu-item", {active: pageMode === PageMode.CAMERA_SIZE,})}`: 
                `${cx("main-menu-item", {active: pageMode === PageMode.CAMERA_SIZE,})}`}
              onClick={vehicleValue? handleCameraNav: null }
            >
              CAMERA SIZE
            </span>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <span
              className={cx("main-menu-item", {
                active: pageMode === PageMode.LENS_LENGTH,
              })}
            >
              LENS LENGTH
            </span>
          </Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="main-content">
        {pageMode === PageMode.STAGE_DIMENSIONS && (
          <div className="stage-content">
            <h1 className="stage-title">Get Help Planning Your Stage!</h1>
            <p className="stage-subtitle">Answer some questions below.</p>
            <img
              className="stage-arrow"
              src={arrow}
              alt="Stage Arrow"
            ></img>
          </div>
        )}
        {pageMode !== PageMode.STAGE_DIMENSIONS && (
          <CanvasContainer></CanvasContainer>
        )}
      </div>
    </>
  );
}

export { MainContainer };
