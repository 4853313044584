import React from "react";
import { BottomContainer } from "../components/BottomContainer";
import { MainContainer } from "../components/MainContainer";
import { SideContainer } from "../components/SideContainer";
import "../styles/main.scss";

function MainPage() {
  return (
    <div className="main-container">
      <div className="top-container">
        <div className="main-container">
          <MainContainer></MainContainer>
        </div>
        <div className="side-container">
          <SideContainer></SideContainer>
        </div>
      </div>
      <div className="bottom-container">
        <BottomContainer></BottomContainer>
      </div>
    </div>
  );
}

export { MainPage };
