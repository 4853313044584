import { combineReducers } from "redux";
import { main, MainState } from "./MainReducer";

export interface State {
  main: MainState;
}

const rootReducer = combineReducers({
  main,
});

export default rootReducer;
