import "../styles/side-container.scss";
import { useSelector } from "react-redux";
import { State } from "../reducers";
import { PageMode } from "../constants";

function SideContainer() {
  const pageMode = useSelector((state: State) => state.main.pageMode);

  return (
    <div className="side-content">
      {pageMode === PageMode.STAGE_DIMENSIONS && (
        <>
          <h3 className="title">Information and Directions about this Tool</h3>
          <p className="item">
            Figuring out how to build a stage for vehicles can be difficult. We
            created this tool to demonstrate the concept of lens length while
            planning. Find what Lens Length you need for your camera and ensure
            that a vehicle's profile will fit in the frame.
          </p>
          <p className="item">
            Fill in your answers in the footer and let the plan start to unfold.
          </p>
          <h3 className="title">Stage Dimensions</h3>
          <p className="item">
            What are the dimensions of the area you plan to take photos in?
            Enter the Length and Width of the area and click the next button.
            Otherwise, you can specify how far the camera is from the vehicle.
          </p>
        </>
      )}
      {pageMode === PageMode.VEHICLE_SIZE && (
        <>
          <h3 className="title">
            Planning for the Vehicle Sizes you plan to photograph
          </h3>
          <p className="item">
            When planning your stage or photo area, the design process should
            take into consideration the size of vehicles you plan to shoot. The
            camera for compact vehicles can be closer or use a longer lens
            length. For extended vehicles over 20 ft, there will need to be more
            distance between the vehicle and the camera or a shorter lens length
            can be used.
          </p>
          <p className="item">
            Note that shorter lens length that are considered wide angle can
            distort images.
          </p>
          <h3 className="title">Vehicle Classes</h3>
          <p className="item">
            Select a vehicle class from the dropdown on the input bar and select
            next to see the vehicle class in the stage. Choose the largest
            vehicle class you plan to shoot.
          </p>
        </>
      )}
      {pageMode === PageMode.CAMERA_SIZE && (
        <>
          <h3 className="title">The Camera Matters</h3>
          <p className="item">
            The camera is an important variable when planning the stage.
            Different manufacturers and models have different Sensor sizes. A
            Full Frame Sensor will capture more information and a wider field of
            view than a smaller sensor.
          </p>
          <p className="item">
            The smaller the lens length, the wider field of view is captured by
            the sensor.
          </p>
          <h3 className="title">Camera Sensor</h3>
          <p className="item">
            We have listed some of the most common sensors that are found in
            professional cameras. You may need to Google your camera model +
            sensor size. If your camera does not have one of these sensor sizes,
            you may want to upgrade or consult with Ovad to ensure high quality
            images.
          </p>
          <p className="item">
            Select the Sensor Size from the drop-down list in the input bar.
          </p>
        </>
      )}
      {pageMode === PageMode.LENS_LENGTH && (
        <>
          <h3 className="title">The Results</h3>
          <p className="item">
            Check the bottom bar for your results. The lens for your camera
            needs to be capable of this length or smaller.
          </p>
          <h4 className="title">Lens Length Ranges</h4>
          <ul>
            <li className="item">Ultra-wide</li>
            <li className="item">Wide</li>
            <li className="item">Normal</li>
            <li className="item">Telephoto</li>
          </ul>
          <h3 className="title">Distortion with Wide Angles</h3>
          <p className="item">
            The smaller the lens length, the wider the field of view of the
            image. This can be required with space restrictions. However, an
            ultrawide and wide angle lenses do cause distortion in the images.
          </p>
          <p className="item">
            Try to increase the distance of the camera to the vehicle or use a
            camera with a full frame sensor to reduce image distortion if your
            results were in the Ultra-Wide Lens Length Range.
          </p>
        </>
      )}
    </div>
  );
}

export { SideContainer };
