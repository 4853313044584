import "../styles/bottom-container.scss";
import { Button, InputNumber, Checkbox, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { State } from "../reducers";
import { MAIN_ACTIONS, PageMode, VEHICLE, CAMERA } from "../constants";

const { Option } = Select;

function BottomContainer() {
  const pageMode = useSelector((state: State) => state.main.pageMode);
  const dispatch = useDispatch();

  const stageDimensions = useSelector(
    (state: State) => state.main.stageDimensions
  );

  const isDistance = useSelector(
    (state: State) => state.main.stageDimensions.isDistance
  );

  const vehicle = useSelector((state: State) => state.main.vehicle);
  const camera = useSelector((state: State) => state.main.camera);
  const cameraLensLength = useSelector(
    (state: State) => state.main.cameraLensLength
  );

  function onChangeLength(value) {
    stageDimensions.length = value;
    dispatch({
      type: MAIN_ACTIONS.UPDATE_STAGE_DIMENSION,
      payload: stageDimensions,
    });
  }

  function onChangeWidth(value) {
    stageDimensions.width = value;
    dispatch({
      type: MAIN_ACTIONS.UPDATE_STAGE_DIMENSION,
      payload: stageDimensions,
    });
  }

  function onChangeDistance(value) {
    stageDimensions.distance = value;
    dispatch({
      type: MAIN_ACTIONS.UPDATE_STAGE_DIMENSION,
      payload: stageDimensions,
    });
  }

  function onChangeIsDistance() {
    stageDimensions.isDistance = !stageDimensions.isDistance;
    dispatch({
      type: MAIN_ACTIONS.UPDATE_STAGE_DIMENSION,
      payload: stageDimensions,
    });
  }

  function onChangeVehicle(value) {
    dispatch({
      type: MAIN_ACTIONS.UPDATE_VEHICLE,
      payload: value,
    });
  }

  function onChangeCamera(value) {
    dispatch({
      type: MAIN_ACTIONS.UPDATE_CAMERA,
      payload: value,
    });
  }

  function isAllowNext() {
    switch (pageMode) {
      case PageMode.STAGE_DIMENSIONS:
        return (
          (stageDimensions.width > 0 &&
            stageDimensions.length > 0 &&
            !stageDimensions.isDistance) ||
          (stageDimensions.isDistance && stageDimensions.distance > 0)
        );
      case PageMode.VEHICLE_SIZE:
        return !!vehicle;
      case PageMode.CAMERA_SIZE:
        return !!camera;
    }
  }

  function handleNext() {
    switch (pageMode) {
      case PageMode.STAGE_DIMENSIONS:
        dispatch({
          type: MAIN_ACTIONS.UPDATE_PAGE_MODE,
          payload: PageMode.VEHICLE_SIZE,
        });
        break;
      case PageMode.VEHICLE_SIZE:
        dispatch({
          type: MAIN_ACTIONS.UPDATE_PAGE_MODE,
          payload: PageMode.CAMERA_SIZE,
        });
        break;
      case PageMode.CAMERA_SIZE:
        dispatch({
          type: MAIN_ACTIONS.UPDATE_PAGE_MODE,
          payload: PageMode.LENS_LENGTH,
        });
        break;
    }
  }

  return (
    <>
      <div className="bottom-left">
        {pageMode === PageMode.STAGE_DIMENSIONS && (
          <div className="inputs-container">
            <span className="input-label">Length (ft):</span>
            <InputNumber
              disabled={isDistance}
              bordered
              min={1}
              max={100000}
              value={stageDimensions.length}
              placeholder="Length"
              style={{ width: "12.5%" }}
              size="large"
              type="number"
              onChange={onChangeLength}
            ></InputNumber>
            <span className="input-label">Width (ft):</span>
            <InputNumber
              disabled={isDistance}
              bordered
              min={1}
              max={100000}
              value={stageDimensions.width}
              placeholder="Width"
              size="large"
              style={{ width: "12.5%" }}
              type="number"
              onChange={onChangeWidth}
            ></InputNumber>
            <Checkbox
              className="distance-checkbox"
              onChange={onChangeIsDistance}
              checked={isDistance}
            ></Checkbox>
            <span className="distance-label">
              Distance from Camera to Vehicle (ft):
            </span>
            <InputNumber
              disabled={!isDistance}
              min={1}
              max={100000}
              value={stageDimensions.distance}
              placeholder="Distance"
              style={{ width: "12.5%" }}
              size="large"
              type="number"
              onChange={onChangeDistance}
            ></InputNumber>
          </div>
        )}
        {pageMode === PageMode.VEHICLE_SIZE && (
          <div className="inputs-container">
            <span className="input-label">Vehicle:</span>
            <Select
              style={{ width: "20%" }}
              size="large"
              value={vehicle}
              onChange={onChangeVehicle}
            >
              <Option value={VEHICLE.COMPACT}>Compact (Max 15ft)</Option>
              <Option value={VEHICLE.FULLSIZE}>FullSize (Max 18ft)</Option>
              <Option value={VEHICLE.TRUCKS}>
                Trucks and Extended (Max 20ft)
              </Option>
            </Select>
          </div>
        )}
        {pageMode === PageMode.CAMERA_SIZE && (
          <div className="inputs-container">
            <span className="input-label">Camera Size:</span>
            <Select
              style={{ width: "20%" }}
              size="large"
              value={camera}
              onChange={onChangeCamera}
            >
              <Option value={CAMERA.FULL_FRAME}>Full Frame (36 X 24mm)</Option>
              <Option value={CAMERA.DX_NIKON}>
                DX Nikon, Sony (23.5 X 15.6mm)
              </Option>
              <Option value={CAMERA.CANNON}>Canon APS-C (22.3 X 14.9mm)</Option>
            </Select>
          </div>
        )}
        {pageMode === PageMode.LENS_LENGTH && (
          <div className="inputs-container">
            {!isNaN(cameraLensLength) && (
              <span className="result-label">
                {cameraLensLength > 0 &&
                  `The focal length of the lens needs to be a maximum of ${cameraLensLength}mm`}
                {cameraLensLength === -1 &&
                  `Your camera is too close to the vehicle in order to take a complete profile of the vehicle. 
                  Try from a larger distance away...`}
              </span>
            )}
          </div>
        )}
      </div>
      <div className="bottom-right">
        {pageMode !== PageMode.LENS_LENGTH && (
          <Button
            className="next-button"
            type="primary"
            shape="round"
            size="large"
            onClick={handleNext}
            disabled={!isAllowNext()}
          >
            NEXT
          </Button>
        )}
        <div className="logo"></div>
      </div>
    </>
  );
}

export { BottomContainer };
