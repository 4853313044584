export const MAIN_ACTIONS = {
  UPDATE_STAGE_DIMENSION: "UPDATE_STAGE_DIMENSION",
  UPDATE_PAGE_MODE: "UPDATE_PAGE_MODE",
  UPDATE_VEHICLE: "UPDATE_VEHICLE",
  UPDATE_CAMERA: "UPDATE_CAMERA",
  UPDATE_CAMERA_ANGLE: "UPDATE_CAMERA_ANGLE",
  UPDATE_CAMERA_LENS_LENGTH: "UPDATE_CAMERA_LENS_LENGTH",
};

export const STAGE_BORDER_WIDTH_IN_PIXEL = 5;

export enum PageMode {
  STAGE_DIMENSIONS = "stage_dimensions",
  VEHICLE_SIZE = "vehicle_size",
  CAMERA_SIZE = "camera_size",
  LENS_LENGTH = "lens_length",
}

export enum VEHICLE {
  COMPACT = "compact",
  FULLSIZE = "fullsize",
  TRUCKS = "trucks",
}

export const VEHICLE_WIDTH_IN_FT = 6;

export enum CAMERA {
  FULL_FRAME = "full_frame",
  DX_NIKON = "dx_nikon",
  CANNON = "cannon",
}

export const CAMERA_LENS = {
  [CAMERA.FULL_FRAME]: {
    121.9: 10,
    117.1: 11,
    112.6: 12,
    108.3: 13,
    104.3: 14,
    100.4: 15,
    96.7: 16,
    93.3: 17,
    90.0: 18,
    86.9: 19,
    84.0: 20,
    78.6: 22,
    73.7: 24,
    69.4: 26,
    65.5: 28,
    61.9: 30,
  },
  [CAMERA.CANNON]: {
    93.8: 10,
    88.3: 11,
    83.3: 12,
    78.8: 13,
    74.7: 14,
    70.9: 15,
    67.4: 16,
    64.3: 17,
    61.4: 18,
    58.7: 19,
    56.2: 20,
    51.8: 22,
    48.0: 24,
    44.7: 26,
    41.8: 28,
    39.2: 30,
  },
  [CAMERA.DX_NIKON]: {
    96.9: 10,
    91.4: 11,
    86.5: 12,
    81.9: 13,
    77.7: 14,
    73.9: 15,
    70.4: 16,
    67.1: 17,
    64.1: 18,
    61.4: 19,
    58.8: 20,
    54.3: 21,
    50.3: 22,
    46.9: 24,
    43.9: 26,
    41.2: 28,
    35.7: 30,
  },
};

export const CAMERA_LENS_LENGTH_RANGE = {
  [CAMERA.CANNON]: {
    "Ultra-Wide": {
      start: 10,
      end: 15,
    },
    "Wide": {
      start: 16,
      end: 18,
    },
  },
  [CAMERA.DX_NIKON]: {
    "Ultra-Wide": {
      start: 10,
      end: 16,
    },
    "Wide": {
      start: 17,
      end: 19,
    },
  },
  [CAMERA.FULL_FRAME]: {
    "Ultra-Wide": {
      start: 10,
      end: 24,
    },
    "Wide": {
      start: 25,
      end: 32,
    },
  },
};
